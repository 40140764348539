import * as React from "react";
import injectSheet from "react-jss";
import { Image } from 'cloudinary-react'
import Button from "../Button/Button";
import HQTheme from "../../theme/theme";

const spaceEvenly = {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

const styles = {
    appCard: {
        backgroundColor: '#fff',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-evenly',
        flexDirection: 'column',
        height: '250px',
        width: '150px',
        minWidth: '150px',
        margin: '12px',
        cursor: 'pointer',
        color: '#666',
        transition: 'box-shadow 300ms linear',
        userSelect: 'none',
        '&:hover': {
            boxShadow: '0 14px 28px rgba(0,0,0,0.16), 0 10px 10px rgba(0,0,0,0.23)'
        }
    },
    appCardAppInfo: {
        flex: '1 100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        "& > :first-child": {
            margin: '0 0 12px 0'
        }
    },
    appCardButtons: {
        display: 'flex',
        flex: '0 120px',
        flexDirection: 'column',
        visibility: 'hidden',

        "& > a": {
            height: '32px'
        },
        "& > a:first-of-type": {
            margin: '0 0 8px 0'
        },
        "& > a:last-of-type": {
            backgroundColor: 'transparent',
            color: HQTheme.palette.text.secondary,
            fontSize: '14px',

            "&:hover": {
                color: HQTheme.palette.text.primaryContrast,
            }
        }
    }
}

interface AppProps {
    name: string
    slug: string
    logo: string
}

interface AppCardProps {
    classes?: any
    app: AppProps
}


const AppCard = ({ classes: c, app }: AppCardProps) => {
    return (
        <div className={c.appCard}>
            <div className={c.appCardAppInfo}>
                <Image cloudName="hq-app" secure publicId={`appIcons/${app.logo}`} width={56} crop="scale" dpr="2.0" />
                <div className={c!.appCardApp}>{app.name}</div>
            </div>
            <div className={c.appCardButtons}>
                <Button internalLink={'#'} label="Get started" />
                <Button internalLink={`/apps/${app.slug}`} label="More info" />
            </div>
        </div>
    )
}


export default injectSheet(styles)(AppCard)
