import * as React from 'react'
import classNames from 'classnames';
import injectSheet from 'react-jss';
import Layout from "../components/layout";
import Page from '../components/Page/Page';
import AppCard from '../components/AppCard';
import DragScroll from 'react-dragscroll'
import FloatingTabs from '../components/FloatingTabs';
import FloatingTab from '../components/FloatingTabs/FloatingTab';

const styles = {
    apps: {
        width: '100%',
        margin: '24px 0'
    },
    supportedApps: {
        margin: '32px auto',
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        justifyContent: 'center',
        overflowX: 'auto',
        minHeight: '300px',

        "@media (max-width: 767px)": {
            justifyContent: 'initial',
            width: '100%',
        }
    },
    supportedAppsWrapper: {
        width: 'calc(6 * 174px)',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        "@media (max-width: 767px)": {
            justifyContent: 'initial',
            flexWrap: 'noWrap'
        }
    }
}
interface AppsProps {
  classes?: any,
  location?: any
}

interface IApp {
    name: string
    slug: string
}

const releasedApps = ['Google Drive', 'Slack', 'DropBox', 'Gmail', 'One Drive']
const enterpriseApps = ['SalesForce', 'Sharepoint', 'Docs', 'Twitter', 'Safari', 'Firefox', 'Blogger', 'Soundcloud', 'Spotify', 'Skype', 'Trello', 'Powerpoint']
const upcomingApps = [
    'Youtube', 
    'Reddit', 
    'Yelp', 
    'Outlook', 
    'Wunderlist', 
    'Etsy', 
    'Wordpress', 
    'Woo Commerce', 
    'Buffer', 
    'Toggl', 
    'Stripe', 
    'Squarespace', 
    'Slideshare', 
    'Path', 
    'Pocket', 
    'Wikipedia', 
    'Linkedin', 
    'Office365', 
    'Mysql', 
    'Github', 
    'Mailchimp', 
    'Jira',
    'Vimeo'
]
const earlyAccessApps = [
    'hubspot',
    'Harvest', 
    'Hangouts', 
    'Google Analytics', 
    'Docusign', 
    'Pinterest', 
    'Quora', 
    'Discord', 
    'Shopify', 
    'Confluence', 
    'Foursquare', 
    'Atlassian', 
    'Basecamp', 
    'AmazonS3', 
    'Lightroom', 
    'Evernote', 
    'Excel', 
    'Tumblr', 
    'Premier', 
    'Box', 
    'Word', 
    'Aftereffects', 
    'Discord', 
    'Bing'
]

const createApps = (apps: any) => {
    return apps.map((app: any) => {
        return {
            name: app,
            slug: app.split(' ').join('-').toLowerCase(),
            logo: app.split(' ').join('').toLowerCase(),
        }
    })
}

const appCategories = [
    {
        name: 'released', 
        apps: createApps(releasedApps)
    },
    {
        name: 'enterprise', 
        apps: createApps(enterpriseApps)
    },
    {
        name: 'upcoming', 
        apps: createApps(upcomingApps)
    },
    {
        name: 'early acces', 
        apps: createApps(earlyAccessApps)
    },
]



const Apps = ({ classes: c, location }: AppsProps) => {  
    const [appCategory, setAppCategory] = React.useState(0)    
    
    return (
        <Layout location={location}>
            <Page title={'Supported apps'} subtitle={'Extend your workflow within HQ'} noMargin>
                <div className={classNames(c.apps, 'apps')}>
                    <FloatingTabs onTabClick={setAppCategory}>
                        {
                            appCategories.map((category, index) => {
                                return <FloatingTab key={category.name} id={index} label={category.name} />
                            })
                        }
                    </FloatingTabs>
                    <DragScroll className={c.supportedApps}>
                        <div className={c.supportedAppsWrapper}>
                        {
                            appCategories[appCategory].apps.map((app: IApp) => {
                                return <AppCard key={app.name} app={app} />
                            })
                        }
                        </div>
                    </DragScroll>
                </div>
            </Page>
        </Layout>
    )
}


export default injectSheet(styles)(Apps)
